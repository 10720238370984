<template>
	<div>
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<!-- 타이틀 -->
				<v-col
					align="center"
					class="pa-0"
				>
					<v-row class="ma-0 py-5 justify-center main_tit">
						<v-col
							class="pa-0"
							cols="auto"
							align-self="center"
						>
							<v-img
								width="24"
								eager
								:src="require('@/assets/img/careflo/icon_care_flo.svg')"
							/>
						</v-col>
						<v-col
							class="pa-0 ml-1"
							align-self="center"
							cols="auto"
						>
							케어플로 서비스
						</v-col>
					</v-row>
				</v-col>

				<!-- 바코드업로드 -->
				<v-col
					cols="12"
					class="pa-4 mb-5 section"
				>
					<h3 class="mb-2">바코드 업로드</h3>
					<p class="mb-5 desc">촬영 버튼을 눌러 촬영을 진행해주세요!</p>
					<v-col
						class="pa-0 py-8 btn_camera"
						@click="cameraLayer = true"
					>
						<div
							v-if="!CAREFLO_GET_BARCODE_ITEM.image"
							class="d-flex justify-center"
						>
							<v-img
								max-width="24"
								eager
								:src="require('@/assets/img/careflo/icon_camera.svg')"
							/>
							<span class="pl-2">사진촬영</span>
						</div>
						<div
							v-else
							class="d-flex flex-column justify-center align-center"
						>
							<v-img
								class="capture_viewer"
								:width="640"
								max-width="60%"
								eager
								:src="CAREFLO_GET_BARCODE_ITEM.image"
							></v-img>
							<p class="mt-1 mb-5 desc recap">*사진 선택 시 재촬영</p>
						</div>
					</v-col>

					<v-row class="ma-0 mt-3 notice">
						<v-col
							class="pa-0"
							cols="auto"
							align-self="center"
						>
							<v-img
								width="20"
								eager
								:src="require('@/assets/img/careflo/icon_error.svg')"
							/>
						</v-col>
						<v-col
							class="pa-0"
							cols="10"
							align-self="center"
						>
							<div class="ml-2">
								최종 저장이 완료된 사진은 수정이 불가합니다. 수정 필요 시 고객센터로 문의주세요
							</div>
						</v-col>
					</v-row>
				</v-col>

				<!-- 서비스구분 -->
				<v-col
					cols="12"
					class="pa-4 mb-5 section"
				>
					<!-- <h3 class="mb-2">서비스구분</h3>
					<p class="mb-2 desc">풀케어 서비스입니다.</p>
					<v-col
						class="mb-4 py-2 px-4 fullcare_check"
						@click="checkCareType"
					>
						<CommonCheckboxsCheckbox02
							v-model="care_type"
							text="풀케어서비스 입니다."
							class-name="justify-start"
						/>
					</v-col> -->
					<v-btn
						:disabled="!CAREFLO_GET_BARCODE_ITEM.image"
						height="40"
						block
						color="black"
						elevation="0"
						class="btn_save"
						@click="confirmSave = true"
					>
						저장
					</v-btn>
				</v-col>

				<!-- 고객센터 접속 -->
				<v-col class="mb-15 pa-0 px-4">
					<div class="center_info">
						<v-row class="ma-0 justify-center">
							<v-col
								class="pa-0"
								cols="auto"
								align="end"
							>
								<div class="mr-1 txt_1">고객센터</div>
							</v-col>
							<v-col
								class="pa-0"
								cols="auto"
							>
								<div class="ml-2">
									<v-col class="pa-0 txt_2">1899-2837</v-col>
									<v-col class="pa-0 txt_3">평일 9:00 ~ 17:00(공휴일 외)</v-col>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-col>

				<!-- 사진촬영 -->
				<HomeCarefloCamera
					v-if="cameraLayer"
					:camera-layer="cameraLayer"
					@close="cameraLayer = false"
				/>
			</v-container>
		</div>
		<CommonConfirm
			:dialog="confirmCareType"
			:title="``"
			:text="`바닥 샌딩을 진행하는 현장이 맞나요?`"
			mobile
			@close="
				care_type = false
				confirmCareType = false
			"
			@submit="
				care_type = true
				confirmCareType = false
			"
		/>
		<CommonConfirm
			:dialog="confirmSave"
			:title="``"
			:text="confirmMsg"
			mobile
			@close="confirmSave = false"
			@submit="saveData"
		/>
	</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'CareFloStoreScan',
	mixins: [common],
	data: () => ({
		cameraLayer: false,
		care_type: false,
		confirmCareType: false,
		confirmSave: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER', 'CAREFLO_GET_BARCODE_ITEM']),
		confirmMsg() {
			return `한번 저장된 사진은 수정이 불가합니다.<br>
					사진을 저장하시겠습니까?
					${
						this.CAREFLO_GET_BARCODE_ITEM.barcode
							? '<br><br><div style=color:#262626;>일련번호 :<strong> ' +
							  this.CAREFLO_GET_BARCODE_ITEM.barcode +
							  '</strong></div>'
							: ''
					}`
		},
	},
	watch: {},
	mounted() {},
	created() {},
	methods: {
		...mapMutations(['APP_MU_DIALOG', 'CAREFLO_MU_BARCODE_ITEM']),
		...mapActions(['CAREFLO_ACT_STORE_BARCODE_SAVE']),
		checkCareType() {
			if (this.care_type) this.confirmCareType = true
		},
		async saveData() {
			const params = {
				nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code,
				barcode_file_base64: this.CAREFLO_GET_BARCODE_ITEM.image,
				barcode: this.CAREFLO_GET_BARCODE_ITEM.barcode,
				care_type: this.care_type ? 'full' : 'one',
				operator_hand: this.CAREFLO_GET_AUTH_USER.hand,
				reg_date: this.common_get_current_full_date(),
			}

			await this.CAREFLO_ACT_STORE_BARCODE_SAVE(params).then(res => {
				console.log('res', res)
				if (res) {
					this.APP_MU_DIALOG({
						dialog: true,
						icon: 'careflo/icon_clear.svg',
						title: '저장 완료!',
						text: '사진 저장이 완료되었습니다.<br />내용 확인 후 종료해주세요.',
					})
					this.confirmSave = false
					this.$router.push(`/careflo/store/upload`)
				}
			})
		},
	},
}
</script>

<style scoped lang="scss">
// 사진촬영버튼
.btn_camera {
	cursor: pointer;
	background-color: $color_gray_1;
	border: 1px solid $color_gray_3;
	border-radius: 8px;
	font-size: $font_lg;
	font-weight: $fw_bold;
	color: $color_font;
}

.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.section {
	background-color: $color_white;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}

	p {
		font-size: $font_normal;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
		&.recap {
			max-width: 224px;
			width: 100%;
			font-size: $font_sm;
			font-weight: normal;
		}
	}

	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
	.btn_save {
		:deep(.v-btn__content) {
			color: $color_gray_10;
			font-size: $font_normal;
			font-weight: $fw_bold;
		}
	}
}

.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.fullcare_check {
	border: 1px solid $color_gray_3;
	border-radius: 8px;
}

.notice {
	color: $color_gray_8;
	font-size: $font_sm;
	word-break: keep-all;
}
.capture_viewer {
	max-width: 224px !important;
	max-height: 300px !important;
	aspect-ratio: 10/16;
}
</style>
